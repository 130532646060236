// 1.0引入axios
import axios from 'axios'
// import qs from 'qs'
import local from '@/utils/local.js'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// 2.0 设置公共的请求地址和超时
// axios.defaults.baseURL = 'http://47.113.111.247:80'
// axios.defaults.timeout = 5000
const service = axios.create({
   baseURL: 'http://localhost:7001', // url = base api url + request url
    withCredentials: false, // send cookies when cross-domain requests
    timeout:3000000 // request timeout
})

// 3.0请求和响应拦截
// 添加请求拦截器
service.interceptors.request.use(config => {
    NProgress.start()
    config.headers.post['Content-Type'] = 'application/json'
    return config
})

// 添加响应拦截器
service.interceptors.response.use(res => {
    NProgress.done()
    // return res.data
    const result = res.data.response || res.data;
    //if (result.hasOwnProperty('status') && result.status === 0) result.code = 0
    if (result.code !== 0) {
        if (result.code === 500 || result.code === 501) {
            return Promise.resolve(result)
        } else if (result.code === 8005) {
            //token失效
        } else {
            return Promise.reject(result.code || 'error')
        }
    } else {
        return Promise.resolve(result)
    }

})
export default service
