const api = {
    base_url : "../..",
    // base_url:'http://47.113.111.247:64931',
    link_url:'http://'+  window.location.host,
    register:'/register/auth',//注册
    getCode:'/register/sms',//获取验证码
    sysTypeList: '/sys/dict/list',//获取字典
    selectAllPage:'/requirementPc/selectAllPage',//已发布公开需求
    requireDetails:'/requirementPc/selectById',//需求详情
    teamList:'/mhEntryObject/selectPcPage',//行业专家
    teamselectById:'/mhEntryObject/selectById',//详情
    aboutUsId:'/aboutUs/selectById',//关于我们
    focusFiled:'/focusFiled/select',//领域简介
    newsPolicy:'/newsPolicy/selectAll',//新闻
    newsDetails:'/newsPolicy/selectById',//新闻详情
    logout:'/userDetail/logout',//退出登陆
    saveMessage:'/userMessage/save',//发布留言
    getPageList:'/mhAchievementMid/queryFrontPageList',//查询成果列表
    gettsDetails:'/mhTechnicalSecret/queryFrontById',//根据tsId查看技术秘密前台
    getcdDetails:'/mhCopyrightDetail/queryFrontById',//根据cdId查看软著详情前台
    getodDetails:'/mhOtherAchievements/queryFrontById',//根据oaId查看其他成果前台
    getadDetails:'/achievementDetail/queryFrontById', //根据adId查看专利详情前台
    getStatisticsData:'/mhAchievementMid/getStatisticsData', //首页统计成果、行业专家、科研团队、技术团队等数据
    gethomePage:'/achievement/queryFrontPageList',//首页成果概况分页列表
    getBanner:'/mhAchievementMid/getCarouselChart',//首页轮播图
    getAPageList:'/mhAchievementMid/queryFrontPageList',//首页成果详情列表
    getFPageList:'/mhFriendshipLinks/queryFrontPageList',//首页友情链接分页列表
    getserviceList:'/serviceDetail/selectPcPage',//分页查询前台概况
    selectByAchievementId:'/serviceDetail/selectByAchievementId',//根据概况id查询服务详情
    selectproductId:'/productDetail/selectByAchievementId',//根据概况id查询服务详情
    selectViews:'/userReviews/select',//查看评论
    viewsSave:'/userReviews/save',//发表评论
    mhEntryObject:'/mhEntryObject/selectPc',//概况信息

    MidselectList:'/mhAchievementMid/selectList',//获取转化项目列表,

    getservice:'/serviceDetail/getCarouselChart',//服务轮播
    getproduct:'/productDetail/getCarouselChart',//服务轮播
    
    queryFontById:'/projectDetail/queryFontById',//项目成果详情
    queryReward:'/achievement/queryReward',//获取获奖清单
}

export default api
