
// 主页API

// 引入封装好的 request.js 文件
import req from '@/utils/request.js'
import api from '@/config/api.js'

// 环境变量设置baseURL
const _api = process.env.VUE_APP_BASE_URL
const _server = process.env.VUE_APP_BASE_SERVER


// GET 请求 params：{key：value}
export function getRequst (url, params) {
    return req({
        url: _api + url,
        method: 'get',
        params,
        hideloading: false
    })
}

// POST请求
export function postRequest (url, params) {
    return req({
        url: _api + url,
        method: 'post',
        data: params,
        hideloading: false
    })
       
}

